import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'
import { BreadCrumb, Section, Tags, ComingSoon } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from '../components/Icons/PlusIcon'
import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon';
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
// our services
const OurSolutionTitle = styled.div`
 
`
const HeadingWrapper = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const HeadingItem = styled.div`
  padding-left:15px;
  padding-right:15px;
`
const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 border-top:1px solid #CCCCCC;
 padding-top:40px;
 ${BreakpointDown.xl`
  padding-top:20px;
  padding-bottom:0;
  margin-top: 20px;
  text-align:center;
 `}
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
flex:0 0 100%;
text
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
 display:none;
 `}
}
`
const OurSolutionListRight = styled.div`
flex:1;
padding-left:90px;
padding-top:20px;
${BreakpointDown.xl`
padding-left:0;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`

`
// faq




const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SectionBg = styled(Section)`
  position:relative;
  box-shadow: 0px 20px 85px #00000033;
  z-index: 2;
&:before, &:after{
  width:50%;
  ${BreakpointDown.xl`
    display:none;
    `
  }
}
.container{z-index:1}
`

const FaqTitle = styled.div`
 text-transform:uppercase;
 margin-bottom:0;
`

const FaqRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
`
const FaqColumn = styled.div`
  flex: 0 0 50%;
  width:50%;
  position:relative;
  padding-top:150px;
  padding-bottom:150px;
  
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%;
  `}
  &:first-child{
    border-right: 1px solid #00000033;
    box-shadow: 1px 0px 2px 0px #00000033;
    background:#fff;
    ${BreakpointDown.xl`
      padding-top:30px;
      padding-bottom:0px;
      border-right:0;
      box-shadow:none;
    `
  }
  }
  &:only-child{
    flex: 0 0 100%;
      width:100%;
      border-right:0;
      box-shadow:none
  }
  &:last-child{
    padding: 105px;
    ${BreakpointDown.xl`
        padding: 30px 0px;
      `
  }
   &:before{
     content:"?";
     font-size:800px;
     font-weight:900;
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
     font-family: "Nunito Sans", sans-serif;
     text-transform:uppercase;
     letter-spacing: -8px;
     color: #000000;
     opacity: 0.05;
     display:none;
     ${BreakpointDown.xl`
       display:none;
    `
  }
   }
  }
`
const TabList = styled.div` 
`
const TabListDesktop = styled(TabList)`
display:none;
${BreakpointUp.xl`
   display:inherit
`
  }
`
const TabListMobile = styled(TabList)`
display:none;
${BreakpointDown.xl`
   display:inherit
   `
  }
`
const TabListItem = styled.div`
padding-top:20px;
padding-bottom:20px;
display:flex;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
margin-bottom:0;
position: relative;
font-family: "Nunito Sans", sans-serif;
line-height: 24px;
font-weight: bold;
cursor: pointer;
${BreakpointDown.xl`
  flex-direction:column;
  background:#fff;
  border-bottom: 1px solid #00000033;
  padding-left:15px;
padding-right:15px;
 &:nth-of-type(){
  border-top: 1px solid #00000033;
 }
 `}
 ${BreakpointDown.md`
  padding-right:35px;
 `}

svg{
  transition: all 0.1s linear;
  position:absolute;
  right:0;
  ${BreakpointDown.md`
    right:-15px;
 `}
}
&:before{
    content:'';
    background:#F8F8F8;
    position:absolute;
    left:0;
    right:-40px;
    top:0;
    bottom:0;
    display:none;
    ${BreakpointDown.xl`
     right:0;
    `}
  }
&+&{
  border-top: 1px solid #00000033;
}
&.active{
  >div{
    color:#000;
  }
  svg{
    transform: rotate(45deg);
    path{
      fill:#000;
    }
  }
  .icon{
     display:block;
     ${BreakpointDown.xl`
    display:none;
    `}
  
  }
&:before{
  display:block;
}
}
`
const Text = styled.div`
  position:relative;
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  color:#666666;
`
const Icon = styled.div`
  position:absolute;
  right:-30px;
  display:none;
  margin-top: 8px;
  transform: rotate(224deg);

 svg{
   margin-left:15px;
   path{
     fill:#000;
   }
 }
`
const TabContnet = styled.div`
  position:relative;
  z-index:1;
 
`
const TabContentItem = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.15s linear;
  &.tab-item{
    ${BreakpointUp.xl`
    display:none;
    `
  }
    &.active{
      ${BreakpointUp.xl`
        display:inherit;
        `
  }
    .tab-content-inner{
      padding-top:20px;
      height:auto;
      overflow:auto;
    }
    }
  }
`
const TabContentinner = styled.div`
   ${BreakpointDown.xl`
      height:0;
      overflow:hidden;
      transition: height 0.15s linear; 
    `
  }
`
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);

  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`
function ServicePage() {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };
  return (
    <Layout pageName="service">
      <SEO
        title="Structured  Cabling Solutions and Data Closet Installation - WYN Technologies"
        description="WYN Technologies specializes in efficient structured Low voltage cabling and data closet installation and design for businesses of all sizes. Get your Quote Now."
      />
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link to="/">Services</Link><Link to="/#">Structured Low Voltage Cabling Network for Voice and Data</Link>
              </BreadCrumb>
              <ServiceTitle>Structured Low Voltage Cabling Network for Voice and Data</ServiceTitle>
              <MonitoringSolutionsItemContent>
                <p>What’s the key to ensuring the optimal performance of your business network? It all starts with designing and organizing your network cabling installation properly in the first place! But what structured cabling companies can you trust to take care of your business? Look no further than <strong>WYN Technologies</strong>!</p>

                <p>WYN provides clean, efficient structured cabling installation and network racking solutions, along with effective remediation services for any existing cabling you want to preserve. The result is a better ergonomic design, decreased troubleshooting downtime, and a network that best serves your needs. IT hardware asset management is one of our specialties at WYN!</p>

              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
              <ImgWrapper>
                <StaticImage
                  src="../images/cabling.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </ImgWrapper>
            </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <Section bgColor="#F8F8F8" pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <HeadingWrapper>
            <HeadingItem>
              <OurSolutionTitle className="h1">What We Offer with Our Structured Wiring, Network Cabling Services, & Other Related Services</OurSolutionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
            </HeadingItem>
          </HeadingWrapper>

          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Voice and Data Cabling Installation & Technical Consultation</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>A voice/data cabling network is really the lifeblood of most modern businesses. And <strong>WYN</strong> specializes in the design and installation of structured cabling systems to provide a reliable, effective telecommunications infrastructure for all sorts of businesses and commercial facilities. </p>
                <p>WYN uses the best cabling product solutions, but we do more than that. We’ll partner with you to design the perfect network system for your business, and we have the know-how and experience to get it installed for you correctly the first time. We take the quality of our workmanship seriously! We’ll make sure you understand everything about how your network is set up, too.</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Business Telephone Systems & VoIP Solutions</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Looking for the best business phone system to meet your needs? <strong>WYN</strong> can help you cut costs versus an older analog or site-based PBX system by switching to a cloud-based phone system. We have many, many attractive VoIP phone system options to offer, and one of them is bound to be perfect for you! And here’s another clear advantage of choosing a VoIP solution – you won’t be required to purchase and maintain a lot of expensive hardware. Most VoIP phone products are essentially plug-and-play! Plus, you’ll enjoy lots of bonus features with VoIP that you simply won’t get anywhere else.</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Setting up a wireless network is really another necessity for modern businesses. Not only is having WiFi convenient, practical, and important for providing the right customer experience; it can also be configured with maximum security protocols. Thankfully, <strong>WYN</strong> has plenty of experience installing Wireless Access Points (WAPs) for businesses and commercial entities, and we know all the ins and outs when it comes to wireless network setup. We can also leverage wireless heat mapping to help identify any current dead spots or weak signal points that might need addressing. When it comes to wireless networks, if you need it, we can do it!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>          
        </div>
      </Section>
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <SectionBg bgBefore="#fff" bgAfter="#fff">
        <div className="container">
          <FaqRow>
            <FaqColumn>
              <FaqTitle className="h1">faq</FaqTitle>

              <TabListDesktop>
                {[
                  {
                    title: 'What is low-voltage cabling, exactly?',
                  },
                  {
                    title: 'What are the primary components of a structured cabling system?',
                  },
                  {
                    title: 'How does a structured cabling system work?',
                  },
                  {
                    title: 'What’s the difference between structured cabling and voice/data cabling?',
                  },
                  {
                    title: 'Why does my business need structured cabling?',
                  },
                  {
                    title: 'How much does structured cabling cost?',

                  }
                ].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title} <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>

            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[
                  {
                    title: 'What is low-voltage cabling, exactly?',
                    content: 'You’re probably already familiar with high-voltage wiring; this is what carries electricity to all of your power outlets, lights, fixtures, and switches. Low-voltage cabling, on the other hand, serves a different purpose. Also known as structural cabling, these lower voltage wires power technologies, including phones, internet, fire alarms, and security camera systems. Low-voltage cabling is really the network which handles the bulk of your business technology infrastructure.'
                  },
                  {
                    title: 'What are the primary components of a structured cabling system?',
                    content: `It’s really just that. Internal hardware is located inside the main computer shell or tower itself, while external peripherals are located outside of the main computer housing. Peripherals can be connected to the system by direct cable, or they can be part of a wireless network of connected devices.
                    `
                  },
                  {
                    title: 'How does a structured cabling system work?',
                    content: 'Essentially, a structured cabling system supports multiple hardware and software applications, and should help businesses to be well-equipped for meeting both current and future needs. Structured cabling is the basic infrastructure which supports computer networks, IP voice/data networks, internet connections, WiFi access points, surveillance systems, smart devices, and more.'
                  },
                  {
                    title: 'What’s the difference between structured cabling and voice/data cabling?',
                    content: 'The terms “structured cabling” and “voice/data cabling” are often used synonymously. In a literal sense, voice/data cabling is just a more specific subset of structured cabling applications. In most cases, though, structured cabling and voice/data cabling can essentially be considered one and the same, since both can be used to support and connect phone systems, computer systems, data networks, internet networks, and surveillance/alarm systems.'
                  },
                  {
                    title: 'Why does my business need structured cabling?',
                    content: 'That’s a great question! Is structured cabling something you really need? In a word, yes. In a very literal sense, an effective voice/data cable system is really the glue which holds your business phone, computer, and other connected devices network together. Having the right structured cabling in place ensures that your network will provide you with performance that’s both optimal and reliable for things like internet speed, conference calls, video chats, and other essential applications specific to your business. A well-planned cabling system is also much easier to troubleshoot in the event of any problems that may crop up down the line.'
                  },
                  {
                    title: 'How much does structured cabling cost?',
                    content: 'In order to get an accurate estimate of what your structured cable installation will cost, there’s some information you’ll need to gather upfront. Your chosen provider will need a copy of your building’s blueprints, and will need to know when your building was originally constructed. They’ll also need to know whether this is a brand-new wiring project, or an upgrade to your existing structured network. WYN Technologies is happy to come out and do a free initial site evaluation for you. We can help you to determine how many drop locations you’ll need, where your server room should be placed, and how your wiring should be placed. Got questions about our structured low-voltage cabling services? Just reach out today, and let’s get the conversation started!'
                  }
                ].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title}<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                        <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow>
        </div>
      </SectionBg>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <Blogs>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
          </Blogs> */}
        </div>
      </Section>
    </Layout>
  )
}

export default ServicePage
